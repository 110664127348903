import * as React from "react";

import ContactMeForm from "../components/ContactMeForm/ContactMeForm";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto minmax(200px, 1200px) auto;
`;

export const Button = styled.button`
  &:hover {
    background-color: #361b0d;
    color: #ffb400;
  }
  border-width: 8px;
  background-color: #cbab5e;
  color: #502c1a;
  border-radius: 0px;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.5s;
`;

export const Section = styled.section`
  display: flex;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

export const Divider = styled.div`
  & {
    border: 1px solid var(--white);
    display: inline-block;
    width: 12%;
    border-width: 2px;
  }
`;

export const Col = styled.div`
  width: 50%;
  text-align: center;
  padding-top: 10%;
  padding-right: 5%;
  padding-bottom: 10%;
  padding-left: 5%;
  color: var(--white);

  h2 {
    font-size: 36px;
    padding-bottom: 10px;
  }

  p {
    font-size: 18px;
    line-height: 1.8em;
    margin-top: 25px;
    word-wrap: break-word;
  }

  a {
    text-decoration: underline;
  }

  @media (max-width: 980px) {
    width: initial;
    padding-right: 10%;
    padding-left: 10%;

    p {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const Banner = styled.section`
  text-align: center;
  font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  padding-top: 5%;
  padding-bottom: 5%;
  background: #1e1d1c;
  h2 {
    font-size: 40px;
    padding-bottom: 1%;
    color: #fff;
  }
  section {
    text-transform: uppercase;
    border: 1px solid;
    width: 60px;
    height: 70px;
  }
`;

export const FinishesRow = styled.div`
  color: "white";
  display: "flex";
  flex-wrap: "wrap";
  align-items: "center";
`;

export const ImageTextRow = styled.section`
  display: grid;
  grid-template-columns: 2fr 1fr;
  color: #fff;
  @media (max-width: 980px) {
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

export const TextColumn = styled.div`
  background-color: #efe9e4;
  padding-top: 30%;
  padding-right: 15%;
  padding-bottom: 30%;
  padding-left: 15%;

  h2 {
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    font-size: 36px;
    line-height: 1.2em;
  }

  p {
    font-family: "Muli", Helvetica, Arial, Lucida, sans-serif;
    color: #666;
    font-size: 18px;
    line-height: 1.8em;
    margin-top: 25px;
    margin-bottom: 5px;
  }

  button {
    margin-top: 25px;
  }

  @media (max-width: 980px) {
    padding-top: 10%;
    padding-bottom: 10%;
    order: 2;
    grid-row: 1;
  }
`;

export const ContactMeRow = styled.section`
  padding-bottom: 120px;
  background-color: #1e1d1c;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IndexPage = () => {
  return (
    <>
      <SEO title="home" />
      <Container>
        <aside style={{ background: "#406370" }}></aside>
        <div style={{ display: "grid" }}>
          <StaticImage
            style={{
              gridArea: "1/1",
              height: "85vh",
            }}
            layout="fullWidth"
            loading="eager"
            objectFit="cover"
            aspectRatio={1 / 1}
            formats={["auto", "webp", "avif"]}
            alt="Alex decor ceiling mirror decorated"
            src="../images/home/ceiling.jpg"
            placeholder="blurred"
          />
          <div
            style={{
              gridArea: "1/1",
              position: "relative",
              placeItems: "center",
              display: "grid",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#0000007a",
                color: "#e6f0ff",
                fontSize: "2.5rem",
                width: "70%",
                maxWidth: "720px",
                padding: "3rem 4rem",
                textAlign: "center",
                position: "relative",
                top: "-15%",
              }}
            >
              <h1>High quality decors!</h1>
              <p>
                Plaster specialist based in London. Creative and experienced
                decorating services.
              </p>
              <Link to="/projects">
                <Button type="button">Visit projects</Button>
              </Link>
            </div>
          </div>
        </div>
        <aside style={{ background: "#320b1a" }}></aside>
      </Container>

      {/* 2 column text */}
      <Container>
        <aside style={{ background: "#406370" }}></aside>
        <Section>
          <Col style={{ backgroundColor: "rgb(64, 99, 112)" }}>
            <h2>Bespoke design</h2>
            <Divider />
            <p>
              AlexDecor service is suitable for all types of interiors, from
              splash backs to feature walls to commerical shop fittings. With so
              many different finishes available it’s a modern and flexible way
              to create a stunning finish.
            </p>
            <p>
              I have applied decor to all manor of locations, including
              bathrooms, offices and living rooms and many other.
            </p>
          </Col>
          <Col style={{ backgroundColor: "rgb(50, 11, 26)" }}>
            <h2>How can I help</h2>
            <Divider />
            <p>
              Professional advise for finish and color pallete
              <br />
              Large range of finishes
              <br />
              Fair time and price quote
              <br />
              Friendly and caring service
            </p>
            <p>
              <Link to="/contactUs">
                <Button type="button">Ask anything</Button>
              </Link>
            </p>
          </Col>
        </Section>
        <aside style={{ background: "#320b1a" }}></aside>
      </Container>

      {/* Finishes row */}
      <Container>
        <aside style={{ background: "#1e1d1c" }}></aside>
        <Banner>
          <h2>Finishes</h2>
          <div
            style={{
              color: "white",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "45%",
                padding: "20px",
                textAlign: "center",
              }}
            >
              <h3>Marmorino</h3>
              <p>
                A smooth, highly polished surface that resembles marble. This
                finish is perfect for creating a luxurious and elegant look in
                any room, and is especially well-suited for living rooms, dining
                rooms, and bathrooms.
              </p>
              <StaticImage
                objectFit="cover"
                aspectRatio={1 / 1}
                formats={["auto", "webp", "avif"]}
                alt="marmorine wall sample"
                src="https://res.cloudinary.com/dpsctz6li/image/upload/c_scale,h_200,q_auto:best,w_200/v1646348447/alex-decor/home-page/finishes-samples/marmurine-sample_gyfuef.png"
                placeholder="blurred"
              />
            </div>
            <div style={{ width: "45%", padding: "20px", textAlign: "center" }}>
              <h3>Venetian Plaster</h3>
              <p>
                Known for its smooth and glossy surface. This finish is often
                applied in thin layers to create a sense of depth and movement,
                making it perfect for creating a dramatic and dynamic look in
                any space.
              </p>
              <StaticImage
                objectFit="cover"
                aspectRatio={1 / 1}
                formats={["auto", "webp", "avif"]}
                alt="travertine wall sample"
                src="https://res.cloudinary.com/dpsctz6li/image/upload/c_scale,h_200,q_auto:best,w_200/v1646348447/alex-decor/home-page/finishes-samples/travertino-sample_flb5lg.png"
                placeholder="blurred"
              />
            </div>
            <div style={{ width: "45%", padding: "20px", textAlign: "center" }}>
              <h3>Travertine/Sandstone</h3>
              <p>
                Textured and resemble natural stone, making them perfect for
                creating a warm and inviting atmosphere in any room.
              </p>
              <StaticImage
                objectFit="cover"
                aspectRatio={1 / 1}
                formats={["auto", "webp", "avif"]}
                alt="travertine wall sample"
                src="https://res.cloudinary.com/dpsctz6li/image/upload/c_scale,h_200,q_auto:best,w_200/v1646348447/alex-decor/home-page/finishes-samples/travertino-sample_flb5lg.png"
                placeholder="blurred"
              />
            </div>
            <div style={{ width: "45%", padding: "20px", textAlign: "center" }}>
              <h3>Microcement/Tadelakt</h3>
              <p>
                Ultra-thin finish that is perfect for creating a sleek and
                modern look in any space, especially in bathrooms and kitchens.
                Tadelakt is a traditional Moroccan finish which is waterproof
                and can be used in bathrooms and kitchens, giving a unique and
                exotic touch to the space.
              </p>
              <StaticImage
                objectFit="cover"
                aspectRatio={1 / 1}
                formats={["auto", "webp", "avif"]}
                alt="art beton sample"
                src="https://res.cloudinary.com/dpsctz6li/image/upload/c_scale,h_200,q_auto:best,w_200/v1646348446/alex-decor/home-page/finishes-samples/art-beton-sample_maxf43.png"
                placeholder="blurred"
              />
            </div>
          </div>
        </Banner>
        <aside style={{ background: "#1e1d1c" }}></aside>
      </Container>

      {/* Image + Text row */}
      <Container>
        <aside style={{ background: "#282625" }}></aside>

        <ImageTextRow>
          <StaticImage
            style={{ border: "3rem solid #282625" }}
            objectFit="cover"
            aspectRatio={1 / 1}
            formats={["auto", "webp", "avif"]}
            alt="art beton with a couch, chair and a lamp on the wooden floor"
            src="../images/home/bg-jumbotron-min.jpeg"
            placeholder="blurred"
          />
          <TextColumn style={{ backgroundColor: "#282624" }}>
            <h2>As individal as you</h2>
            <Divider />
            <p style={{ color: "#fff" }}>
              Either you need advice or work on your room painting, adding the
              decor with the finish of your chose and the one that matches
              sunlight and the walls.
            </p>
            <p style={{ color: "#fff" }}>
              I offer friendly service and a fair price on materials and working
              hours.
            </p>
            <p style={{ color: "#fff" }}>
              I also do paintings that matches your interior.
            </p>
          </TextColumn>
        </ImageTextRow>
        <aside style={{ background: "#282625" }}></aside>
      </Container>

      {/* Contact Me */}
      <Container>
        <aside style={{ background: "#1e1d1c" }}></aside>

        <section>
          <ContactMeRow>
            <Col style={{ paddingBottom: "3%" }}>
              <h2>Got a quick question for me?</h2>
              <Divider />
              <div style={{ padding: "3% 3%" }}>
                <a
                  style={{ color: "rgba(203, 171, 94, 1)" }}
                  href="tel:+447545277074"
                >
                  +447545277074
                </a>
              </div>

              <div>
                Are you looking for expert advice or information on our
                plastering and decorating services? Reach out to us today! Fill
                out our quick and easy contact form or drop us an email and our
                team will promptly respond to any inquiries you may have.
                Whether you have a specific project in mind or simply want to
                learn more about how we can enhance your home, we are here to
                help. Don't hesitate, let's start the conversation today!
              </div>
              <div style={{ padding: "3% 3%" }}>
                <address>
                  <a
                    style={{ color: "rgba(203, 171, 94, 1)" }}
                    href="mailto:info@alexdecor.co.uk"
                  >
                    info@alexdecor.co.uk
                  </a>
                </address>
              </div>
            </Col>
            <ContactMeForm />
          </ContactMeRow>
        </section>
        <aside style={{ background: "#1e1d1c" }}></aside>
      </Container>
    </>
  );
};

export default IndexPage;
