import * as React from "react";
import styled from "styled-components";
import useForm from "../../utils/useForm";

export const StyledForm = styled.form`
  color: #686868;
  display: flex;
  flex-direction: column;
  width: 50%;

  input,
  textarea {
    font-size: 14px;
    padding: 16px;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }
  button {
    align-self: center;
    margin-top: 20px;
  }

  @media (max-width: 980px) {
    width: 70%;
    button {
      align-self: center;
    }

    input,
    textarea {
      padding: 16px 0px;
      text-indent: 10px;
    }
  }
`;

export const StyledInput = styled.input`
  position: absolute;
  top: -9999px;
`;

export const NameAndEmailRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 22px;
  margin-bottom: 22px;
  @media (max-width: 980px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
    margin-bottom: 12px;
  }
`;

const Button = styled.button`
  &:hover {
    background-color: #361b0d;
    color: #ffb400;
  }
  border-width: 8px;
  background-color: #cbab5e;
  color: #502c1a;
  border-radius: 0px;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.5s;
`;

export default function ContactMeForm() {
  const { values, updateValue } = useForm({
    name: "",
    email: "",
    message: "",
  });

  return (
    <>
      <StyledForm
        name="contact"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <input hidden name="bot-field" />
        <NameAndEmailRow>
          <input
            type="text"
            name="name"
            value={values.name}
            placeholder="Name"
            onChange={updateValue}
            required
          />
          <input
            type="email"
            name="email"
            value={values.email}
            placeholder="Email Address"
            onChange={updateValue}
            required
          />
        </NameAndEmailRow>
        <textarea
          rows={10}
          name="message"
          value={values.message}
          placeholder="Message"
          onChange={updateValue}
          required
        />
        <Button type="submit">Submit</Button>
      </StyledForm>
    </>
  );
}
